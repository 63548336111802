import React, { Component } from "react";
import Layout from "../layout";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import HTMLContent from "../components/htmlcontent";
import _ from "lodash";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false
    };
  }
  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };
  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const aboutHtml = this.props.data.markdownRemark.html;
    const pic1 = this.props.data.pic1.childImageSharp.gatsbyImageData;
    const pic2 = this.props.data.pic2.childImageSharp.gatsbyImageData;
    return (
      <Layout>
        <section className="">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column" style={{ wordBreak: "break-word" }}>
                  <h1 className="title is-size-2">
                    welcome to 
                    <span style={{display: "inline-block"}}><span className="has-text-primary"> trinity</span>'s
                    corner of the&nbsp;internet</span>
                  </h1>

                  <div className="is-size-5">
                    {<HTMLContent html={aboutHtml} />}
                  </div>
                </div>
                <div
                  className="column"
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                >
                  <div style={{ width: "fit-content", margin: "0 auto" }}>
                    {this.state.isHovered ? (
                      <GatsbyImage image={pic2} />
                    ) : (
                      <GatsbyImage image={pic1} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default HomePage;

export const pageQuery = graphql`
  query HomePageQuery {
    markdownRemark(frontmatter: { for: { eq: "about" } }) {
      html
    }
    pic1: file(name: { eq: "2024-pittsburgh" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 300)
      }
    }
    pic2: file(name: { eq: "2024-blahaj-crop" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 300)
      }
    }
  }
`;
